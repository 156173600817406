import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./ProductDetails.module.css";
import Header from "../Header";

interface Product {
  id: number;
  name: string;
  description: string;
  price: number;
  image: string;
}

const customList: any[] = [
  {
    'id': 1,
    'image': 'https://images9.kabum.com.br/produtos/fotos/542929/console-playstation-5-slim-ssd-1tb-edicao-digital-branco-2-jogos-digitais-1000038914_1728305312_g.jpg',
    'description': 'Console PlayStation 5 Slim Sony, SSD 1TB, Edição Digital, Com Controle Sem Fio DualSense, Branco + 2 Jogos Digitais ',
    'price': 2999
  },
  {      'id': 2,

    'image': 'https://images7.kabum.com.br/produtos/fotos/150657/placa-de-video-galax-geforce-rtx-3060-1-click-oc-15-gbps-12gb-gddr6-ray-tracing-dlss-36nol7md1voc_1614253646_g.jpg',
    'description': 'Placa de Vídeo RTX 3060 1-Click OC Galax NVIDIA GeForce, 12GB GDDR6, LHR, DLSS, Ray Tracing',
    'price': 1639.99
  },
  {
    'id': 3,
    'image': 'https://images7.kabum.com.br/produtos/fotos/471927/cadeira-gamer-kbm-gaming-cg600-branco-com-almofadas-descanso-para-pernas-retratil-reclinavel-kgcg600br_1700660778_g.jpg',
    'description': 'Cadeira Gamer KBM! GAMING Tempest CG600, Branco, Com Almofadas, Descanso Para Pernas Retrátil, Reclinável',
    'price': 699.90
  },
  {
    'id': 4,
    'image': 'https://images2.kabum.com.br/produtos/fotos/80632/80632_index_g.jpg',
    'description': 'SSD SATA Sandisk, 240GB, 2.5" Leitura: 530MB/s e Gravação: 440MB/s Preto - SDSSDA-240G-G26',
    'price':  199.99
  },
  {
    'id': 6,

    'image': 'https://images1.kabum.com.br/produtos/fotos/460471/echo-dot-5-geracao-amazon-com-alexa-smart-speaker-preto-b09b8vgcr8_1684324203_g.jpg',
    'description': 'Echo Dot 5ª geração Amazon, com Alexa, Smart Speaker, Preto',
    'price': 314.90
  },
  {
    'id': 7,

    'image': 'https://images8.kabum.com.br/produtos/fotos/444038/monitor-gamer-lg-ultragear-27-full-hd-144hz-1ms-ips-hdmi-e-displayport-hdr-10-99-srgb-freesync-premium-vesa-27gn65r_1712149543_g.jpg',
    'description': 'Monitor Gamer LG UltraGear 27", 144Hz, Full HD, 1ms, IPS, DisplayPort e HDMI, HDR 10, 99% sRGB, FreeSync Premium, VESA',
    'price': 650.90
  },
  {
    'id': 8,

    'image': 'https://images6.kabum.com.br/produtos/fotos/649716/notebook-acer-gamer-nitro-v15-anv15-51-7837-intel-core-i7-8gb-ram-ddr5-15-6-nvidia-rtx-3050-512gb-ssd-linux-preto-nh-qqdal-008_1730306499_g.jpg',
    'description': 'Notebook Gamer Acer Nitro V15 ANV15-51-7837 Intel Core I7, 8GB RAM, DDR5, Nvidia RTX 3050, 512GB SSD, 15.6", Linux, Preto',
    'price': 3850.90
  },
  {
    'id': 9,

    'image': 'https://images7.kabum.com.br/produtos/fotos/117767/microfone-gamer-hyperx-quadcast-s-led-usb-preto-e-vermelho-hmiq1s-xx-rg-g_1602169935_g.jpg',
    'description': 'Microfone Gamer HyperX QuadCast S Podcast, Antivibração, LED RGB, USB, Compatível com PC, PS4 e Mac, Preto',
    'price': 330.90
  },
 
  // Adicione os demais produtos...
];

const ProductDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const product = customList.find((p) => p.id === Number(id));

  useEffect(() => {
    const checkScreenSize = () => setIsMobile(window.innerWidth <= 768);
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  if (!product) {
    return <p>Produto não encontrado.</p>;
  }

  const handleAddToCart = () => {
    alert("Produto adicionado ao carrinho!");
  };

  return (
    <div className={styles.container}>
      <Header />
      <div
        className={
          isMobile ? styles.productDetailsMobile : styles.productDetailsDesktop
        }
      >
        <div className={styles.imageContainer}>
          <img
            src={product.image}
            alt={product.description}
            className={styles.image}
          />
        </div>
        <div className={styles.infoContainer}>
          <h1 className={styles.name}>{product.description}</h1>
          <p className={styles.price}>R$ {product.price.toFixed(2)}</p>
          <button
            onClick={handleAddToCart}
            className={styles.addToCartButton}
          >
            Comprar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;