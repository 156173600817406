import React from "react";
import "./style.css";
import Products from "../Products";





const ProductList = () => {


  var customList = [
    {
      'id': 1,
      'image': 'https://images9.kabum.com.br/produtos/fotos/542929/console-playstation-5-slim-ssd-1tb-edicao-digital-branco-2-jogos-digitais-1000038914_1728305312_g.jpg',
      'description': 'Console PlayStation 5 Slim Sony, SSD 1TB, Edição Digital, Com Controle Sem Fio DualSense, Branco + 2 Jogos Digitais ',
      'price': 2999
    },
    {      'id': 2,

      'image': 'https://images7.kabum.com.br/produtos/fotos/150657/placa-de-video-galax-geforce-rtx-3060-1-click-oc-15-gbps-12gb-gddr6-ray-tracing-dlss-36nol7md1voc_1614253646_g.jpg',
      'description': 'Placa de Vídeo RTX 3060 1-Click OC Galax NVIDIA GeForce, 12GB GDDR6, LHR, DLSS, Ray Tracing',
      'price': 1639.99
    },
    {
      'id': 3,
      'image': 'https://images7.kabum.com.br/produtos/fotos/471927/cadeira-gamer-kbm-gaming-cg600-branco-com-almofadas-descanso-para-pernas-retratil-reclinavel-kgcg600br_1700660778_g.jpg',
      'description': 'Cadeira Gamer KBM! GAMING Tempest CG600, Branco, Com Almofadas, Descanso Para Pernas Retrátil, Reclinável',
      'price': 699.90
    },
    {
      'id': 4,
      'image': 'https://images2.kabum.com.br/produtos/fotos/80632/80632_index_g.jpg',
      'description': 'SSD SATA Sandisk, 240GB, 2.5" Leitura: 530MB/s e Gravação: 440MB/s Preto - SDSSDA-240G-G26',
      'price':  199.99
    },
   
  ]



  return (
    <div className="product-list-box">
    {customList.map((product, index) => (
      <Products
        img={product.image}
        productText={product.description}
        productValue={product.price}
        id={product.id} // Certifique-se de que o objeto possui um ID
        key={product.id} // Use um identificador único
      />
    ))}
  </div>
  
  );
};

export default ProductList;
