import React, { useState } from 'react';
import './styles.css';
import IconMenuHamburguer from '../Icons/menu';
import IconSeta from '../Icons/IconSeta';
import IconPerson from '../Icons/IconPerson';
import IconHandPhone from '../Icons/IconHandPhone';
import IconHeart from '../Icons/IconHeart';
import IconStore from '../Icons/IconStore';
import img from '../../LOGO YURI - BRANCO.png'
export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="header">
      {/* Esquerda */}
      <div className="header-left">
        <img src={img} alt="" />
      </div>

      {/* Menu Hamburger para Mobile */}
      <div className="header-hamburger" onClick={toggleMenu}>
        <IconMenuHamburguer />
      </div>

      {/* Menu completo para Desktop */}
      <div className={`header-right ${isMenuOpen ? 'open' : ''}`}>
        <div className="icons">
          <IconPerson />
          <IconHandPhone />
          <IconHeart />
          <IconStore />
        </div>
      </div>
    </div>
  );
}
