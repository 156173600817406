import React from "react";
import "./style.css";
import Product from "../Products";


const DestaqueList = () => {
  var customList = [
    {
      'id': 6,

      'image': 'https://images1.kabum.com.br/produtos/fotos/460471/echo-dot-5-geracao-amazon-com-alexa-smart-speaker-preto-b09b8vgcr8_1684324203_g.jpg',
      'description': 'Echo Dot 5ª geração Amazon, com Alexa, Smart Speaker, Preto',
      'price': 314.90
    },
    {
      'id': 7,

      'image': 'https://images8.kabum.com.br/produtos/fotos/444038/monitor-gamer-lg-ultragear-27-full-hd-144hz-1ms-ips-hdmi-e-displayport-hdr-10-99-srgb-freesync-premium-vesa-27gn65r_1712149543_g.jpg',
      'description': 'Monitor Gamer LG UltraGear 27", 144Hz, Full HD, 1ms, IPS, DisplayPort e HDMI, HDR 10, 99% sRGB, FreeSync Premium, VESA',
      'price': 650.90
    },
    {
      'id': 8,

      'image': 'https://images6.kabum.com.br/produtos/fotos/649716/notebook-acer-gamer-nitro-v15-anv15-51-7837-intel-core-i7-8gb-ram-ddr5-15-6-nvidia-rtx-3050-512gb-ssd-linux-preto-nh-qqdal-008_1730306499_g.jpg',
      'description': 'Notebook Gamer Acer Nitro V15 ANV15-51-7837 Intel Core I7, 8GB RAM, DDR5, Nvidia RTX 3050, 512GB SSD, 15.6", Linux, Preto',
      'price': 3850.90
    },
    {
      'id': 9,

      'image': 'https://images7.kabum.com.br/produtos/fotos/117767/microfone-gamer-hyperx-quadcast-s-led-usb-preto-e-vermelho-hmiq1s-xx-rg-g_1602169935_g.jpg',
      'description': 'Microfone Gamer HyperX QuadCast S Podcast, Antivibração, LED RGB, USB, Compatível com PC, PS4 e Mac, Preto',
      'price': 330.90
    },
   
  ]



  return (
    <div className="product-list-box">
      {customList.map((product, index) => (
        <Product
          img={product.image}
          productText={product.description}
          productValue={product.price}
          key={index}
          id={product.id} 

        />
      ))}
    </div>
  );
};

export default DestaqueList;
