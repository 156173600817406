import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import './style.css';

interface Image {
  src: string;
  alt: string;
}

const images: Image[] = [
  {
    src: 'https://themes.kabum.com.br/conteudo/layout/5535/1731938000.png',
    alt: 'Image 1',
  },
  
  {
    src: 'https://themes.kabum.com.br/conteudo/layout/5543/1732029691.png',
    alt: 'Image 2',
  },


];

export default function Vitrini() {
  return (
    <div className='vitrini-container'>
      <Carousel>
        {images.map((image, index) => (
          <Carousel.Item key={index} interval={3000}>
            <img className="d-block w-100" src={image.src} alt={image.alt} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}