import React, { useState, useEffect } from 'react';
import './styles.css';
import IconTime from '../Icons/IconTime';

interface TimeRemaining {
  hours: number;
  minutes: number;
  seconds: number;
}

export default function Countdown(): JSX.Element {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeRemaining = (): void => {
    const now = new Date();
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999); // Define o final do dia atual

    const timeLeft = endOfDay.getTime() - now.getTime();

    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    setTimeRemaining({ hours, minutes, seconds });
  };

  useEffect(() => {
    calculateTimeRemaining(); // Calcula no carregamento inicial
    const timer = setInterval(calculateTimeRemaining, 1000); // Atualiza a cada segundo
    return (): void => clearInterval(timer); // Limpa o timer ao desmontar
  }, []);

  return (
    <div className="countdown-container">
      <span className="countdown-semana">SEMANA GAMER</span>
      <div className="countdown-item">
        <span className="countdown-label">TERMINA EM: </span>
        <span className="countdown-icon">
          <IconTime />
        </span>
        <span className="countdown-value">{timeRemaining.hours.toString().padStart(2, '0')}:</span>
        <span className="countdown-value">{timeRemaining.minutes.toString().padStart(2, '0')}:</span>
        <span className="countdown-value">{timeRemaining.seconds.toString().padStart(2, '0')}</span>
      </div>
    </div>
  );
}
