import React from 'react';
import './style.css';
import IconHeart from '../Icons/IconHeart';
import IconSellCar from '../Icons/IconSellCar';
import { useNavigate } from "react-router-dom";

// Interface para definir as props esperadas
interface ProductProps {
  img: string;
  productText: string;
  productValue: number;
  id: number;
}

const Products: React.FC<ProductProps> = ({ img, productText, productValue, id }) => {
  const navigate = useNavigate();

  const maxCharacter = 75;

  function limitadorText(text: string): string {
    return text.length <= maxCharacter ? text : text.substring(0, maxCharacter) + "...";
  }

  function formatBRL(value: number): string {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  const handleDetailsClick = () => {
    navigate(`/details/${id}`);
  };

  return (
    <div className="product-box">
      <div className="product-box-top">
        <div className="product-box-icons-top">
          <IconHeart />
          <IconSellCar />
        </div>
      </div>
      <div className="product-box-img">
        <img className="product-img" src={img} alt="" />
      </div>
      <div className="product-box-text">
        <span className="product-text-first">{limitadorText(productText)}</span>
        <span className="product-text-cust">{formatBRL(productValue)}</span>
        <span className="product-text-pix">À vista no PIX</span>
      </div>
      <div className="product-box-bottom">
        <button onClick={handleDetailsClick} className="product-button">
          {/* <IconSellCarWhite /> */}
          <p className="product-button-text">Ver Detalhes</p>
        </button>
      </div>
    </div>
  );
};

export default Products;
