import React from 'react';
import logo from './logo.svg';
import '../App.css';
import Vitrini from './Vitrini';
import Header from './Header';
import Countdown from './Countdown';
import ProductList from './ProductList/ProductList';
import DestaqueList from './DestaqueList/DestaqueList';

function Shop() {
  return (
    <div className="app">
      <Header />
      {/* <HeaderBottom /> */}
      <Vitrini />
      <Countdown />
      <ProductList />
      <div className="details">
      <div className='row-grap'>
          <img src="https://cdn2.newtail.com.br/retail_media/ads/2024/11/20/b31a9020d0ef2a395bf419293d1488b2.raw.png" alt="" />

          <img src="https://cdn2.newtail.com.br/retail_media/ads/2024/11/20/0536973035c86436a450000763ea74df.raw.png" alt="" />
      </div>
      <DestaqueList /> 

      </div>
    </div>
  );
}

export default Shop;
